/*
 * samba2020 - Webpage for https://samba.ccns.sbg.ac.at
 * Copyright (C) 2019-2020 Thomas Hartmann <thomas.hartmann@th-ht.de>
 *
 * This file is part of samba2020.
 *
 * samba2020 is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * samba2020 is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with samba2020.  If not, see <http://www.gnu.org/licenses/>.
 */

#connect-orcid-button{
	border: 1px solid #D3D3D3;
	padding: .3em;
	background-color: #fff;
	border-radius: 8px;
	box-shadow: 1px 1px 3px #999;
	cursor: pointer;
	color: #444;
	font-weight: bold;
	font-size: 0.8em;
	line-height: 64px;
	vertical-align: middle;
}

#connect-orcid-button:hover{
	border: 1px solid #338caf;
	color: #338caf;
}

#orcid-id-icon{
	display: block;
	margin: 0 .5em 0 0;
	padding: 0;
	float: left;
}
