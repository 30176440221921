/*
 * samba2020 - Webpage for https://samba.ccns.sbg.ac.at
 * Copyright (C) 2019-2020 Thomas Hartmann <thomas.hartmann@th-ht.de>
 *
 * This file is part of samba2020.
 *
 * samba2020 is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * samba2020 is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with samba2020.  If not, see <http://www.gnu.org/licenses/>.
 */

html, body {
    height: 100%;
}

body {
    display: flex;
    flex-flow: column;
}

footer {
    margin-top: auto;
    padding-top: 20px;
}

.hidden_field {
    display: none;
}

.select2-selection {
    padding: 1px 10px;
    height: auto;
}

.socialaccount_providers li {
    list-style-type: none;
}

.printfooter {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 60px;
}

.outer-conference-location-map {
    height: auto;
}

.conference-location-map {
    width: 100%;
    height: 100%;
    min-height: 200px;
}

.sponsor-image {
    max-height: 200px;
}

.sponsor-row {
    align-items: baseline;
}

p:empty {
    display: none;
}

.content-col > div {
    margin-bottom: 30px;
}

.richtext-image {
    margin-bottom: 20px;
}

.twitter-timeline-rendered {
    margin-top: -10px !important;
}

.samba_scroll_100 {
    max-height: 100px;
    overflow-y: scroll;
}

ul.registration-details {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}
